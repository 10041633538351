'use client';

import React, { createContext, useContext, useState, useEffect } from 'react';
import { FilterSection, SelectedFilters, Vehicle, SecurityDepositMapping } from "@/types";
import { useTenants } from "./TenantContext";
import { useSecurityDeposits } from "./SecurityDepositsContext";
import { Tenant } from "@prisma/client";
import featuresList from "@/utils/featuresList";
import { filterVehicles } from "@/utils/filterVehicles";
import { useTranslations } from "next-intl";
import { useCurrency } from "@/app/[locale]/CurrencyContext";

interface FiltersContextType {
  filters: FilterSection[];
  setFilters: React.Dispatch<React.SetStateAction<FilterSection[]>>;
  selectedFilters: SelectedFilters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilters>>;
  filteredVehicles: Vehicle[];
}

const FiltersContext = createContext<FiltersContextType | undefined>(undefined);

// Utility function to generate bucket labels dynamically
const generateBuckets = (
  ranges: number[],
  currencySymbol: string,
  exchangeRate: number
) => {
  return ranges.map((range, index) => {
    if (index === 0) {
      return {
        id: `BUCKET_${index + 1}`,
        label: `${currencySymbol}${(range * exchangeRate).toFixed(0)}`,
        count: 0,
      };
    } else if (index === ranges.length - 1) {
      return {
        id: `BUCKET_${index + 1}`,
        label: `${currencySymbol}${(range * exchangeRate).toFixed(0)}+`,
        count: 0,
      };
    } else {
      return {
        id: `BUCKET_${index + 1}`,
        label: `${currencySymbol}${(ranges[index - 1] * exchangeRate).toFixed(0)} - ${currencySymbol}${(range * exchangeRate).toFixed(0)}`,
        count: 0,
      };
    }
  });
};

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { tenants } = useTenants();
  const { securityDepositMapping } = useSecurityDeposits();
  const [filters, setFilters] = useState<FilterSection[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([]);
  const t = useTranslations('VehiclePicker.filters');
  const { currency } = useCurrency();

  // Merge new options with the existing counts
  const mergeWithExistingCounts = (existingOptions: any[], newOptions: any[]) => {
    return newOptions.map((newOption) => {
      const existingOption = existingOptions.find(opt => opt.id === newOption.id);
      return {
        ...newOption,
        count: existingOption ? existingOption.count : 0, // Preserve the existing count
      };
    });
  };

  useEffect(() => {
    const supplierOptions = tenants.map((tenant: Tenant) => ({
      id: tenant.id,
      label: tenant.name,
      count: 0,
    }));

    const featureOptions = featuresList.map((feature) => ({
      id: feature.id,
      label: feature.label,
      count: 0,
    }));

    const pricePerDayBuckets = generateBuckets([50, 100, 150], currency?.symbol || '', currency?.exchangeRate || 1);
    const carDepositBuckets = generateBuckets([0, 300, 600], currency?.symbol || '', currency?.exchangeRate || 1);

    setFilters((prevFilters) => {
      return prevFilters.length === 0
        ? [
            {
              title: t('price_per_day'),
              id: "pricePerDay",
              options: pricePerDayBuckets,
            },
            {
              title: t('car_specs'),
              id: "carSpecs",
              options: featureOptions,
            },
            {
              title: t('mileage'),
              id: "mileage",
              options: [
                { id: "unlimited", label: t('unlimited'), count: 0 },
              ],
            },
            {
              title: t('car_category'),
              id: "carCategory",
              options: [
                { id: "small", label: t('small'), count: 0 },
                { id: "medium", label: t('medium'), count: 0 },
                { id: "large", label: t('large'), count: 0 },
                { id: "premium", label: t('premium'), count: 0 },
                { id: "minivan", label: t('minivan'), count: 0 },
                { id: "suv", label: t('suv'), count: 0 },
              ],
            },
            {
              title: t('car_deposit'),
              id: "carDeposit",
              options: carDepositBuckets,
            },
            {
              title: t('car_supplier'),
              id: "carSupplier",
              options: supplierOptions,
            },
          ]
        : prevFilters.map((section) => {
            if (section.id === "pricePerDay") {
              return {
                ...section,
                options: mergeWithExistingCounts(section.options, pricePerDayBuckets),
              };
            }
            if (section.id === "carDeposit") {
              return {
                ...section,
                options: mergeWithExistingCounts(section.options, carDepositBuckets),
              };
            }
            return section;
          });
    });
  }, [tenants, currency]);

  useEffect(() => {
    // Fetch vehicles from your API or context
    const vehicles: Vehicle[] = []; // Replace with actual vehicle data
    const { filteredVehicles, counts } = filterVehicles(vehicles, selectedFilters, securityDepositMapping);

    setFilteredVehicles(filteredVehicles);

    // Update filter counts based on the filtered vehicles
    const updatedFilters = filters.map(section => ({
      ...section,
      options: section.options.map(option => ({
        ...option,
        count: counts[`${section.id}_${option.id}`] || 0,
      })),
    }));

    setFilters(updatedFilters);
  }, [selectedFilters, securityDepositMapping]);

  return (
    <FiltersContext.Provider value={{ filters, setFilters, selectedFilters, setSelectedFilters, filteredVehicles }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = (): FiltersContextType => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
};
