'use client'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { useTranslations } from "next-intl"
import { UserProfiles } from "@prisma/client"
import { signOutAction } from "../../actions"
import { useRouter } from "next/navigation"
import { UserIcon, GiftIcon, LogOutIcon, AreaChart } from "lucide-react"

interface Props {
  profile: UserProfiles
}

export function ProfileButton({ profile }: Props) {
  const t = useTranslations('Profile')
  const router = useRouter()

  const handleNavigation = (path: string) => {
    router.push(path)
  }

  const menuItems = [
    {
      icon: UserIcon,
      label: 'manageProfile',
      action: () => handleNavigation('/protected/profile'),
    },
    ...(profile.role === 'TENANT_ADMIN' ? [
      {
        icon: AreaChart,
        label: 'dashboard',
        action: () => handleNavigation('/protected/dashboard'),
      }
    ] : []),
    {
      icon: GiftIcon,
      label: 'bookingsTrips',
      action: () => handleNavigation('/protected/reservations'),
    },
    {
      icon: LogOutIcon,
      label: 'signOut',
      action: signOutAction,
      isForm: true,
    },
  ]

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
  }

  return (
    <div className="flex items-center space-x-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
        <Button 
            variant="ghost" 
            className="w-10 h-10 p-0 rounded-full hover:bg-transparent focus:bg-transparent group"
            aria-label={`Profile menu for ${profile.firstName} ${profile.lastName}`}
          >
            <Avatar className="w-full h-full transition-transform group-hover:scale-110 group-focus:scale-110">
              <AvatarImage 
                src={profile.avatarUrl || undefined} 
                alt={`${profile.firstName} ${profile.lastName}`}
                className="object-cover"
              />
              <AvatarFallback className="bg-primary text-primary-foreground group-hover:bg-primary/90 group-focus:bg-primary/90 transition-colors">
                {getInitials(`${profile.firstName} ${profile.lastName}`)}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 mt-2 bg-white shadow-lg rounded-md">
          {menuItems.map((item, index) => (
            <DropdownMenuItem
              key={index}
              className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none cursor-pointer transition-colors duration-150"
              onClick={!item.isForm ? item.action : undefined}
              asChild={item.isForm}
            >
              {item.isForm ? (
                <form action={item.action} className="w-full">
                  <button className="flex items-center w-full text-left">
                    <item.icon className="mr-2 h-4 w-4" />
                    <span>{t(item.label)}</span>
                  </button>
                </form>
              ) : (
                <>
                  <item.icon className="mr-2 h-4 w-4" />
                  <span>{t(item.label)}</span>
                </>
              )}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}