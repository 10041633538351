'use client';

import { ExtrasProvider } from "./ExtrasContext";
import { PlaceProvider } from "./PlaceContext";
import { ReactNode } from "react";
import { SecurityDepositsProvider } from "./SecurityDepositsContext";
import { TenantsProvider } from "./TenantContext";
import { EditBookingProvider } from "./EditBookingContext";
import { FilterProvider } from "./FiltersContext";
import { VehicleProvider } from "./VehiclesContext";
import { SortingProvider } from "./SortingContext";
import { CurrencyProvider } from "./CurrencyContext";

export function Providers({ children }: { children: ReactNode }) {
  return (
    <PlaceProvider>
      <TenantsProvider>
        <CurrencyProvider>
          <SecurityDepositsProvider>
            <EditBookingProvider>
              <ExtrasProvider>
                <FilterProvider>
                  <VehicleProvider>
                    <SortingProvider>
                      {children}
                    </SortingProvider>
                  </VehicleProvider>
                </FilterProvider>
              </ExtrasProvider>
            </EditBookingProvider>
          </SecurityDepositsProvider>
        </CurrencyProvider>
      </TenantsProvider>
    </PlaceProvider>
  );
}
