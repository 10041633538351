import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/MobileNavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileButton"] */ "/vercel/path0/app/[locale]/protected/profile/profile-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/composables/media-rendering.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CurrencySwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/HomeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/LocaleSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/LoginButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/omnes/omnes-regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/omnes/omnes-medium.ttf\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/omnes/omnes-semiBold.ttf\",\"weight\":\"600\"}],\"variable\":\"--font-body\"}],\"variableName\":\"fontBody\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/lota-grotesque/LotaGrotesque-Regular.otf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/lota-grotesque/LotaGrotesque-SemiBold.otf\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/lota-grotesque/LotaGrotesque-Bold.otf\",\"weight\":\"700\"}],\"variable\":\"--font-lota\"}],\"variableName\":\"lotaGrotesque\"}");
