import { Vehicle, SecurityDepositMapping, PriceBuckets, Feature, DepositBuckets } from "@/types";
import { categorizeVehicle } from "./categorizeVehicle";

export const priceBuckets: PriceBuckets = {
  BUCKET_1: { min: 0, max: 50 },
  BUCKET_2: { min: 50, max: 100 },
  BUCKET_3: { min: 100, max: 150 },
  BUCKET_4: { min: 150, max: Infinity }
};

const depositBuckets: DepositBuckets = {
  BUCKET_1: { min: 0, max: 300 },
  BUCKET_2: { min: 300, max: 600 },
  BUCKET_3: { min: 600, max: Infinity }
};

export const filterVehicles = (vehicles: Vehicle[], filters: { [key: string]: string[] }, deposits: SecurityDepositMapping[]) => {
  const filteredVehicles: Vehicle[] = [];
  const countMap: { [key: string]: number } = {};

  const updateCount = (key: string) => {
    if (countMap[key] === undefined) {
      countMap[key] = 0;
    }
    countMap[key] += 1;
  };

  vehicles.forEach(vehicle => {
    const dailyPrice = parseFloat(vehicle.price.details[0].base_daily_price.amount);
    const vehicleCategory = categorizeVehicle(vehicle);

    // Ensure depositAmount is properly calculated
    const deposit = deposits.find(deposit => deposit.tenant_uuid === vehicle.tenant.id && deposit.vehicle_class_id === vehicle.vehicle_class_id);
    const depositAmount = deposit ? parseFloat(deposit.deposit_amount) : 0;

    // Price per day
    Object.keys(priceBuckets).forEach(bucketKey => {
      const bucket = priceBuckets[bucketKey as keyof PriceBuckets];
      if (dailyPrice >= bucket.min && dailyPrice < bucket.max) {
        updateCount(`pricePerDay_${bucketKey}`);
      }
    });

    // Car specs
    vehicle.features.forEach((feature: Feature) => {
      updateCount(`carSpecs_${feature.id}`);
    });

    // Mileage
    if (vehicle.distance_limits.distance_unlimited) {
      updateCount(`mileage_unlimited`);
    }

    // Car category
    if (vehicleCategory) {
      updateCount(`carCategory_${vehicleCategory}`);
    }

    // Car deposit: Adjust deposit logic
    Object.keys(depositBuckets).forEach(bucketKey => {
      const bucket = depositBuckets[bucketKey as keyof DepositBuckets];
      if (depositAmount >= bucket.min && depositAmount < bucket.max) {
        updateCount(`carDeposit_${bucketKey}`);
      }
    });

    // Car supplier
    updateCount(`carSupplier_${vehicle.tenant.id}`);

    // Apply filters
    const matchesFilters = (
      (!filters.pricePerDay || filters.pricePerDay.some(bucketKey => {
        const bucket = priceBuckets[bucketKey as keyof PriceBuckets];
        return dailyPrice >= bucket.min && dailyPrice < bucket.max;
      })) &&
      (!filters.carSpecs || filters.carSpecs.some(spec => vehicle.features.some((f: Feature) => f.id === spec))) &&
      (!filters.mileage || filters.mileage.includes('unlimited') && vehicle.distance_limits.distance_unlimited) &&
      (!filters.carCategory || (vehicleCategory && filters.carCategory.includes(vehicleCategory))) &&
      (!filters.carDeposit || filters.carDeposit.some(bucketKey => {
        const bucket = depositBuckets[bucketKey as keyof DepositBuckets];
        return depositAmount >= bucket.min && depositAmount < bucket.max;
      })) &&
      (!filters.carSupplier || filters.carSupplier.includes(vehicle.tenant.id))
    );

    if (matchesFilters) {
      filteredVehicles.push(vehicle);
    }
  });

  return { filteredVehicles, counts: countMap };
};
