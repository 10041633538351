import { Vehicle } from "@/types";
import { categoryMapping, typeMapping } from "@/utils/sippMapping";

export const categorizeVehicle = (vehicle: Vehicle): string | null => {
  if (vehicle.sipp_code) {
    const categoryCode = vehicle.sipp_code.charAt(0);
    const typeCode = vehicle.sipp_code.charAt(1);

    if (typeMapping[typeCode]) {
      return typeMapping[typeCode];
    }

    if (categoryMapping[categoryCode]) {
      return categoryMapping[categoryCode];
    }
  }

  return null;
};