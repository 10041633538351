'use client';

import { Vehicle } from "@/types";
import React, { createContext, useContext, useState } from 'react';


interface VehicleContextType {
  vehicles: Vehicle[];
  setVehicles: React.Dispatch<React.SetStateAction<Vehicle[]>>;
  filteredVehicles: Vehicle[];
  setFilteredVehicles: React.Dispatch<React.SetStateAction<Vehicle[]>>;
}

const VehicleContext = createContext<VehicleContextType | undefined>(undefined);

export const VehicleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([]);

  return (
    <VehicleContext.Provider value={{ vehicles, setVehicles, filteredVehicles, setFilteredVehicles }}>
      {children}
    </VehicleContext.Provider>
  );
};

export const useVehicles = (): VehicleContextType => {
  const context = useContext(VehicleContext);
  if (context === undefined) {
    throw new Error('useVehicles must be used within a VehicleProvider');
  }
  return context;
};