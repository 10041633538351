'use client'

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AdditionalCharge, ExtrasContextType } from '@/types/index';

const ExtrasContext = createContext<ExtrasContextType | undefined>(undefined);

export const ExtrasProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [extras, setExtras] = useState<AdditionalCharge[]>([]);
  const [selectedExtras, setSelectedExtras] = useState<AdditionalCharge[]>([]);
  const [insurances, setInsurances] = useState<AdditionalCharge[]>([]);
  const [selectedInsurance, setSelectedInsurance] = useState<AdditionalCharge | null>(null);

  return (
    <ExtrasContext.Provider value={{ 
      extras, 
      setExtras, 
      selectedExtras, 
      setSelectedExtras, 
      insurances,
      setInsurances, 
      selectedInsurance, 
      setSelectedInsurance 
    }}>
      {children}
    </ExtrasContext.Provider>
  );
};

export const useExtras = (): ExtrasContextType => {
  const context = useContext(ExtrasContext);
  if (context === undefined) {
    throw new Error('useExtras must be used within an ExtrasProvider');
  }
  return context;
};
