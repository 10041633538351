'use client'

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SecurityDeposit, SecurityDepositsContextType, SecurityDepositMapping } from '@/types/index';

const SecurityDepositsContext = createContext<SecurityDepositsContextType | undefined>(undefined);

export const SecurityDepositsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [securityDeposits, setSecurityDeposits] = useState<SecurityDeposit[]>([]);
  const [securityDepositMapping, setSecurityDepositMapping] = useState<SecurityDepositMapping[]>([]);

  const addSecurityDepositMapping = (mapping: SecurityDepositMapping) => {
    setSecurityDepositMapping(prevMapping => {
      const exists = prevMapping.some(
        m => m.tenant_uuid === mapping.tenant_uuid && m.vehicle_class_id === mapping.vehicle_class_id
      );
      if (!exists) {
        return [...prevMapping, mapping];
      }
      return prevMapping; // Return the previous mapping array if it already exists
    });
  };

  return (
    <SecurityDepositsContext.Provider value={{ 
      securityDeposits, 
      securityDepositMapping,
      setSecurityDeposits,
      setSecurityDepositMapping,
      addSecurityDepositMapping,
    }}>
      {children}
    </SecurityDepositsContext.Provider>
  );
};

export const useSecurityDeposits = (): SecurityDepositsContextType => {
  const context = useContext(SecurityDepositsContext);
  if (context === undefined) {
    throw new Error('useSecurityDeposits must be used within a SecurityDepositsProvider');
  }
  return context;
};
