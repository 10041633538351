'use client'
import React from 'react'
import { sendGAEvent } from "@next/third-parties/google"
import { Button } from "./ui/button"
import { useRouter } from "next/navigation"
import { useTranslations } from "next-intl"

const LoginButton = () => {
  const router = useRouter()
  const t = useTranslations('Profile')

  return (
    <Button onClick={() => {
      sendGAEvent("loginButtonClicked")
      router.push("/login")
    }
    }>{t('login')}</Button>
  )
}

export default LoginButton