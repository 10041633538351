export const categoryMapping: { [key: string]: string } = {
  M: "small",
  N: "small",
  E: "small",
  H: "small",
  C: "medium",
  D: "medium",
  I: "medium",
  S: "large",
  R: "large",
  F: "large",
  G: "large",
  P: "premium",
  U: "premium",
  L: "premium",
  W: "premium"
};

export const typeMapping: { [key: string]: string } = {
  V: "minivan",
  F: "suv",
  J: "suv",
  Q: "suv",
  // Any other type mappings can be ignored or mapped to "unknown"
};
