'use client'

import React, { useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import ReactCountryFlag from "react-country-flag";
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { locales } from "@/config";
import { useTranslations } from 'next-intl';

export default function LocaleSwitcher() {
  const t = useTranslations('LocaleSwitcher');
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [locale, setLocale] = useState(pathname.split('/')[1]);

  const localeFlags: { [key: string]: { code: string; label: string } } = {
    en: { code: 'US', label: 'English' },
    es: { code: 'ES', label: 'Español' },
    nl: { code: 'NL', label: 'Dutch' }
  };

  const onValueChange = (value: string) => {
    setLocale(value);

    const currentLocale = pathname.split('/')[1];
    const newPathname = pathname.replace(`/${currentLocale}`, '') || '/';
    const newPath = `/${value}${newPathname}`;

    const params = new URLSearchParams(searchParams);
    const newUrl = `${newPath}${params.toString() ? `?${params.toString()}` : ''}`;

    router.push(newUrl);
  };

  return (
    <Select
      defaultValue={locale}
      onValueChange={onValueChange}
      aria-label="Select Locale"
    >
      <SelectTrigger className="w-[65px]">
        <SelectValue>
          <ReactCountryFlag
            countryCode={localeFlags[locale].code}
            svg
            style={{ width: '1.5em', height: '1.5em' }}
            aria-label={localeFlags[locale].label}
          />
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {locales.map((cur) => (
          <SelectItem key={cur} value={cur} className="flex items-center gap-2 hover:cursor-pointer">
            <ReactCountryFlag
              countryCode={localeFlags[cur].code}
              svg
              style={{ width: '1.5em', height: '1.5em' }}
              aria-label={localeFlags[cur].label}
            />
            {` ${localeFlags[cur].label}`}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}